import {
    TextField,
    ChipField,
    DateField,
    BooleanField,
    EmailField,
    ArrayField,
    SingleFieldList,
    NumberField,
    SelectField,
    ImageField,
    ReferenceManyField,
    Datagrid,
    ShowButton,
    TabbedShowLayout,
    Tab,
    useGetManyReference,
} from 'react-admin';
import { RawChipField } from '../fields/RawChipField'
import { OfferUrlField } from '../fields/OfferUrlField'
import { SalaryUnit } from 'model';

const salaryUnitChoices = [
    { id: SalaryUnit.HOUR, name: '/ Hour' },
    { id: SalaryUnit.MONTH, name: '/ Month' },
    { id: SalaryUnit.YEAR, name: '/ Year' },
]

export const OfferShowItemFields = ({ hasShow, hasCreate, hasEdit, hasList, ...props }: any) => {
    const { record } = props;
    const { loading: applicationsLoading, ids } = useGetManyReference(
        'applications',
        'offerId',
        record.id,
        { page: 1, perPage: 20 },
        { field: 'applicationDate', order: 'desc' },
        {},
        'offers',
    );
    const applicationsCount = ids.length

    return (
        <TabbedShowLayout {...props}>
            <Tab label="Offer">
                <TextField source="id" />
                <OfferUrlField label="Offer link" />
                <TextField source="title" />
                <ChipField source="status" />
                <TextField source="subject" />
                <TextField source="employmentType" />
                <ArrayField source="levels">
                    <SingleFieldList>
                        <RawChipField />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="jobTitles">
                    <SingleFieldList>
                        <RawChipField />
                    </SingleFieldList>
                </ArrayField>
                <TextField style={{ whiteSpace: 'pre-line' }} source="description" />
                <TextField style={{ whiteSpace: 'pre-line' }} source="candidateRequirements" />
                <TextField style={{ whiteSpace: 'pre-line' }} source="benefits" />
                <TextField source="establishment.name" />
                <TextField style={{ whiteSpace: 'pre-line' }} source="establishment.description" />
                <TextField source="establishment.type" />
                <EmailField source="establishment.contactEmail" />
                <TextField source="establishment.siren" />
                <BooleanField source="emailEnabled" />
                <DateField source="publicationDate" />
                <DateField source="jobStartDate" />
                <DateField source="jobEndDate" />
                <DateField source="expirationDay" />
                <TextField source="contractType" />
                <TextField source="teachingType" />
                <NumberField source="salary.value" locales="fr" options={{ style: 'currency', currency: 'EUR' }} />
                <SelectField source="salary.unit" choices={salaryUnitChoices} />
                <BooleanField source="salary.negotiable" />
                <ImageField source="establishment.logo" />
                <TextField source="prefferedLocale" />
            </Tab>
            <Tab label={`${!applicationsLoading ? applicationsCount : ''} Applications`} >
                <ReferenceManyField label="Applications" reference="applications" target="offerId">
                    <Datagrid>
                        <TextField source="firstName" />
                        <TextField source="lastName" />
                        <TextField source="email" />
                        <ShowButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    )
}