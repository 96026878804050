import { DataProvider } from 'ra-core';
import { API } from '@aws-amplify/api';

const dataProviderBuilder = (
    apiName: string,
    httpClient = API,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
        const { perPage } = params.pagination;
        const { startKey, status } = params.filter;

        const query = {
            startKey,
            status,
            limit: perPage
        };
        const url = `/${resource}`;
        const options = {
            queryStringParameters: query,
        };

        return httpClient.get(apiName, url, options)
            .then((response) => {
                return {
                    data: response.data,
                    total: response.totalItems
                }
            })
    },

    getOne: (resource, params) =>
        httpClient.get(apiName, `/${resource}/${params.id}`, {}).then(({ data }) => ({ data })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids })
        };
        const url = `/${resource}`;
        const options = {
            queryStringParameters: query,
        };

        return httpClient.get(apiName, url, options)
            .then((response) => {
                return {
                    data: response.data,
                    total: response.totalItems
                }
            })
    },

    getManyReference: (resource, params) => {
        const query = {
            filter: JSON.stringify({ [params.target]: params.id })
        };
        const url = `/${resource}`;
        const options = {
            queryStringParameters: query,
        };

        return httpClient.get(apiName, url, options)
            .then((response) => {
                return {
                    data: response.data,
                    total: response.totalItems
                }
            });
    },

    update: (resource, params) =>
        httpClient.put(apiName, `/${resource}/${params.id}`, {
            // method: 'PUT',
            // body: JSON.stringify(params.data),
            queryStringParameters: (params as any).query,
            body: params.data,
        }).then(({ data }) => ({ data })),

    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient.put(apiName, `/${resource}/${id}`, {
                    // method: 'PUT',
                    // body: JSON.stringify(params.data),
                    body: params.data,
                })
            )
        ).then(responses => ({ data: responses.map(({ data }) => data.id) })),

    create: (resource, params) =>
        httpClient.post(apiName, `/${resource}`, {
            // method: 'POST',
            // body: JSON.stringify(params.data),
            body: params.data,
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient.del(apiName, `/${resource}/${params.id}`, {
            // method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({ json }) => ({ data: json })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient.del(apiName, `/${resource}/${id}`, {
                    // method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        })),
});


export default dataProviderBuilder;