import { useEffect, useState } from "react";
import { useListContext } from 'react-admin';
import { Button, Toolbar } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFifoQueue } from '../utils/useFifoQueue';

export const OfferListPagination: React.FC = () => {

    const { ids, loading, data, filterValues, setFilters } = useListContext();
    const [lastKey, setLastKey] = useState<string>('');
    const { add, remove, last, size } = useFifoQueue();

    useEffect(() => {
        if (ids && ids.length > 0) {
            const lastId = ids[ids.length - 1]
            const lastKey = data[lastId].expirationDate
            setLastKey(lastKey as string);
        }
    }, [ids])

    useEffect(() => {
        setFilters({
            ...filterValues,
            startKey: last
        }, {})
    }, [last])

    const prev = () => { remove() }
    const next = () => { add(lastKey) }

    return (
        <>
            {lastKey &&
                (<Toolbar>
                    {loading ? (
                        <CircularProgress size={18} thickness={2} color="inherit" />
                    ) : (
                        <>
                            {size > 0 && (<Button color="primary" key="prev" onClick={prev}>
                                <ChevronLeft />
                                Prev
                            </Button>)}
                            {ids.length > 0 && (<Button color="primary" key="next" onClick={next}>
                                Next
                                <ChevronRight />
                            </Button>)}
                        </>
                    )}
                </Toolbar>)
            }
        </>
    );
}