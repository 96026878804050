import { Edit, SimpleForm, TextInput, DateInput, BooleanInput, RadioButtonGroupInput, CheckboxGroupInput, NumberInput } from 'react-admin';
import { ContractTypeValues, EmploymentTypeValues, JobTitleValues, LevelValues, TeachingTypeValues } from 'model/dist/esm/lib/offer/offer.enum';
import { fromEnum } from '../../lib/utils/fromEnum';
import { SalaryUnit } from 'model';
import { OfferTitle } from '../offer/OfferTitle';

const employmentTypeChoices = fromEnum(EmploymentTypeValues).map(o => ({
    id: o, name: o
}));
const jobTitleChoices = fromEnum(JobTitleValues).map(o => ({ id: o, name: o }));
const levelChoices = fromEnum(LevelValues).map(o => ({ id: o, name: o }));
const localeChoices = [
    { id: 'en', name: 'English' },
    { id: 'fr', name: 'French' },
]
const contractTypeChoices = fromEnum(ContractTypeValues).map(o => ({ id: o, name: o }));
const teachingTypeChoices = fromEnum(TeachingTypeValues).map(o => ({ id: o, name: o }));
const saalaryUnitChoices = fromEnum(SalaryUnit).map(o => ({ id: o, name: o }));


export const StagingOfferEdit = (props: any) => (
    <Edit
        mutationMode="pessimistic"
        title={<OfferTitle />}
        {...props}
    >
        <SimpleForm redirect="show">
            <TextInput source="id" disabled />
            <TextInput source="title" />
            <RadioButtonGroupInput source="employmentType" choices={employmentTypeChoices} />
            <CheckboxGroupInput source="levels" choices={levelChoices} />
            <CheckboxGroupInput source="jobTitles" choices={jobTitleChoices} />
            <TextInput multiline source="description" />
            <TextInput multiline source="candidateRequirements" />
            <TextInput multiline source="benefits" />
            <TextInput source="establishment.name" />
            <TextInput multiline source="establishment.description" />
            <BooleanInput source="emailEnabled" />
            <DateInput source="jobStartDate" />
            <DateInput source="jobEndDate" />
            <RadioButtonGroupInput source="contractType" choices={contractTypeChoices} />
            <RadioButtonGroupInput source="teachingType" choices={teachingTypeChoices} />
            <NumberInput source="salary.value"  />
            <RadioButtonGroupInput source="salary.unit" choices={saalaryUnitChoices} />
            <BooleanInput source="salary.negotiable" />
            <RadioButtonGroupInput source="prefferedLocale" choices={localeChoices} />
        </SimpleForm>
    </Edit>
);
