import {
    List, Datagrid, TextField, DateField, ChipField, ReferenceManyField
} from 'react-admin';
import { ReferenceManyCountField } from '../fields/ReferenceManyCountField';

export const ExpiredOfferList = (props: any) => (
    <List
        perPage={15}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="establishment.name" />
            <TextField source="title" />
            <ChipField source="status" />
            <DateField source="publicationDate" />
            <TextField source="subject" />
            <TextField source="location.city" />
            <ReferenceManyField label="Applications" reference="applications" target="offerId">
                <ReferenceManyCountField />
            </ReferenceManyField>
        </Datagrid>
    </List>
);