import { useState } from 'react';

export const useFifoQueue = <T extends unknown>() => {
    const [arr, setArr] = useState<T[]>([])

    return {
        add: (item: T) => {
            const clone = [...arr];
            clone.push(item);
            setArr(clone);
        },
        remove: () => {
            const clone = [...arr];
            clone.pop();
            setArr(clone);
        },
        first: arr.length > 0 ? arr[0] : undefined,
        last: arr.length > 0 ? arr[arr.length - 1] : undefined,
        size: arr.length
    }
}
