const exports =  {
    Auth: {
        region: 'eu-west-1',
        userPoolId: '{{ USER_POOL_ID }}',
        userPoolWebClientId: '{{ USER_POOL_WEB_CLIENT_ID }}',
        identityPoolId: '{{ IDENTITY_POOL_ID }}',
        mandatorySignIn: true,
    },
    API: {
        endpoints: [
            {
                name: "AdminApi",
                endpoint: '{{ ADMIN_API_ENDPOINT }}',
                region: "eu-west-1"
            },
        ]
    }
}

export default exports;