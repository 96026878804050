import { useListContext } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { StagingStatusOffer } from 'model';

export const StagingOfferEmptyList: React.FC = (props: any) => {

    const { filterValues, displayedFilters, setFilters } = useListContext();
    const loadPublishedStagingOffers = () => {
        setFilters({
            ...filterValues,
            status: StagingStatusOffer.PUBLISHED
        }, {
            status: true
        }, true);
    }

    return (
        <Box textAlign="center" m={10}>
            <Typography variant="h4" paragraph>
                No pending offers
            </Typography>
            <Button
                variant="contained" color="primary"
                onClick={loadPublishedStagingOffers}
            > Load published offers </Button>
        </Box>
    )
}