import {
    Show,
    SimpleShowLayout,
    TextField,
    ChipField,
    DateField,
    BooleanField,
    EmailField,
    Button, TopToolbar, EditButton,
    useMutation,
    ArrayField,
    SingleFieldList,
    Confirm,
    ImageField,
    NumberField,
    SelectField,
} from 'react-admin';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import { useMemo, useState } from 'react';
import { RawChipField } from '../fields/RawChipField'
import { StagingStatusOffer, SalaryUnit } from 'model'
import { OfferUrlField } from '../fields/OfferUrlField'
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { OfferRefuseReason } from 'model/dist/esm/lib/offer/offer.enum';
import { fromEnum } from '../../lib/utils/fromEnum';
import { OfferTitle } from '../offer/OfferTitle';

const refuseReasonsValues = [
    {
        value: OfferRefuseReason.INCOMPLETE_INFO,
        label: 'Incomplete job information'
    },
    {
        value: OfferRefuseReason.INEXACT_ILLEGAL_INFO,
        label: 'Contains illegal content or inaccurate information'
    },
    {
        value: OfferRefuseReason.BABYSITTING_OFFER,
        label: 'Advertises babysitting or childminding positions'
    },
    {
        value: OfferRefuseReason.PRIVATE_LESSON,
        label: 'Advertises for individuals seeking private lessons'
    },
    {
        value: OfferRefuseReason.NATIVE_SPEAKER,
        label: 'Seeks ‘native speakers’ only'
    },
    {
        value: OfferRefuseReason.SALARY_NOT_FAIR,
        label: 'The proposed salary does not correspond with the qualifications and/or level of experience required'
    },
    {
        value: OfferRefuseReason.ESTABLISHMENT_OUTSIDE_FRANCE,
        label: 'Establishment outside France'
    },
];

const StagingOfferEditActions: React.FC<{
    basePath: string;
    data: any;
    resource: string;
}> = ({ basePath, data }) => {

    const [publishConfirmOpen, setPublishConfirmOpen] = useState(false);
    const [refuseConfirmOpen, setRefuseConfirmOpen] = useState(false);
    const initialRefuseReasons = Object.fromEntries(fromEnum(OfferRefuseReason).map(o => ([o, false])))
    const [refuseReasons, setRefuseReasons] = useState<{ [key: string]: boolean }>(initialRefuseReasons);
    const isRefused = useMemo(() => (StagingStatusOffer.REFUSED === data?.status), [data])
    const isPending = useMemo(() => (StagingStatusOffer.PENDING === data?.status), [data])

    const [update, { loading }] = useMutation({
        type: 'update',
        resource: 'staging-offers',
    }, {
        mutationMode: 'pessimistic',
    });
    const btnLabel = useMemo(() => {
        return (data && StagingStatusOffer.PUBLISHED === data.status) ? 'Re-publish' : 'Publish';
    }, [data])

    const approvalIcon = useMemo(() => {
        if (loading) {
            return (<CircularProgress size={18} thickness={2} color="inherit" />)
        }
        return (<CheckCircle />)
    }, [loading])

    const handlePublishDialogClose = () => setPublishConfirmOpen(false);
    const handlePublishConfirm = () => {
        setPublishConfirmOpen(false);
        update({
            payload: { id: data.id, data: null, query: { publish: true } }
        })
    };

    const handleRefuseDialogClose = () => {
        setRefuseReasons(initialRefuseReasons);
        setRefuseConfirmOpen(false)
    };
    const handleRefuseConfirm = () => {
        const refuseReasonsChecked = Object.entries(refuseReasons).reduce<string[]>((prev: string[], curr: [string, boolean]) => {
            const [key, value] = curr;
            if (true === value) {
                prev.push(key)
            }
            return prev;
        }, [])
        if (refuseReasonsChecked.length === 0) {
            return;
        }
        setRefuseConfirmOpen(false);
        update({
            payload: { id: data.id, data: { reasons: refuseReasonsChecked }, query: { refuse: true } }
        });
        setRefuseReasons(initialRefuseReasons);
    };

    const handleRefuseReasonCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRefuseReasons({ ...refuseReasons, [event.target.name]: event?.target.checked })
    }
    return (
        <TopToolbar>
            <EditButton style={{ marginRight: '15px' }} basePath={basePath} record={data} />
            {!isRefused && <Button style={{ marginRight: '5px' }} color="primary" variant="contained" label={btnLabel} disabled={loading} startIcon={approvalIcon} onClick={() => setPublishConfirmOpen(true)}></Button>}
            {isPending && <Button style={{ marginRight: '5px' }} color="default" variant="contained" label="Refuse" disabled={loading} startIcon={<CancelIcon />} onClick={() => setRefuseConfirmOpen(true)}></Button>}
            <Confirm
                isOpen={publishConfirmOpen}
                loading={loading}
                title="Publish on Job board"
                content="Are you sure you want to publish this offer ?"
                onConfirm={handlePublishConfirm}
                onClose={handlePublishDialogClose}
            />
            <Confirm
                isOpen={refuseConfirmOpen}
                loading={loading}
                title="Refuse this offer"
                content={<div style={{ margin: '10px 20px' }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Choose the reason(s)</FormLabel>
                        <FormGroup>
                            {refuseReasonsValues.map(reason => (
                                <FormControlLabel
                                    key={reason.value}
                                    control={<Checkbox checked={refuseReasons[reason.value]}
                                        onChange={handleRefuseReasonCheckboxChange}
                                        name={reason.value} />}
                                    label={reason.label}
                                />
                            ))}
                        </FormGroup>
                    </FormControl>
                </div>
                }
                onConfirm={handleRefuseConfirm}
                onClose={handleRefuseDialogClose}
            />


        </TopToolbar>
    )
};

const salaryUnitChoices = [
    { id: SalaryUnit.HOUR, name: '/ Hour' },
    { id: SalaryUnit.MONTH, name: '/ Month' },
    { id: SalaryUnit.YEAR, name: '/ Year' },
]

export const StagingOfferShow = (props: any) => (
    <Show
        actions={<StagingOfferEditActions {...props} />}
        title={<OfferTitle />}
        {...props}
    >
        <SimpleShowLayout>
            <TextField source="id" />
            <OfferUrlField label="Offer link" />
            <TextField source="title" />
            <ChipField source="status" />
            <TextField source="subject" />
            <TextField source="employmentType" />
            <ArrayField source="levels">
                <SingleFieldList>
                    <RawChipField />
                </SingleFieldList>
            </ArrayField>
            <ArrayField source="jobTitles">
                <SingleFieldList>
                    <RawChipField />
                </SingleFieldList>
            </ArrayField>
            <TextField style={{ whiteSpace: 'pre-line' }} source="description" />
            <TextField style={{ whiteSpace: 'pre-line' }} source="candidateRequirements" />
            <TextField style={{ whiteSpace: 'pre-line' }} source="benefits" />
            <TextField source="establishment.name" />
            <TextField style={{ whiteSpace: 'pre-line' }} source="establishment.description" />
            <TextField source="establishment.type" />
            <EmailField source="establishment.contactEmail" />
            <TextField source="establishment.siren" />
            <BooleanField source="emailEnabled" />
            <DateField source="submissionDate" />
            <DateField source="publicationDate" />
            <DateField source="jobStartDate" />
            <DateField source="jobEndDate" />
            <DateField source="expirationDay" />
            <TextField source="contractType" />
            <TextField source="teachingType" />
            <NumberField source="salary.value" locales="fr" options={{ style: 'currency', currency: 'EUR' }} />
            <SelectField source="salary.unit" choices={salaryUnitChoices} />
            <BooleanField source="salary.negotiable" />
            <ImageField source="establishment.logo" />
            <TextField source="prefferedLocale" />
            <ArrayField source="refusedReasons">
                <SingleFieldList>
                    <RawChipField />
                </SingleFieldList>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);