import {
    List, Datagrid, TextField, DateField, ReferenceField, EmailField
} from 'react-admin';

export const ApplicationList = (props: any) => (
    <List pagination={false} {...props}>
        <Datagrid rowClick="show" isRowSelectable={() => false}>
            <ReferenceField source="offerId" reference="offers" link="show">
                <TextField source="title" />
            </ReferenceField>
            <TextField source="firstName" />
            <TextField source="lastName" />
            <EmailField source="email" />
            <DateField source="applicationDate" />
        </Datagrid>
    </List>
);