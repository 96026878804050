import {
    List, Datagrid, TextField, DateField, EditButton,
    Filter, SelectInput, ChipField
} from 'react-admin';
import { StagingStatusOffer } from 'model';
import { OfferListPagination } from '../pagination/OfferListPagination'
import { StagingOfferEmptyList } from './StagingOfferEmptyList';

const statusChoices = [
    { id: StagingStatusOffer.PENDING, name: 'Pending' },
    { id: StagingStatusOffer.PUBLISHED, name: 'Published' },
    { id: StagingStatusOffer.REFUSED, name: 'Refused' },
];

const StagingOfferFilter = (props: any) => (
    <Filter {...props}>
        <SelectInput label="Status" defaultValue="pending" source="status" choices={statusChoices} />
    </Filter>
);

export const StagingOfferList = (props: any) => (
    <List
        pagination={<OfferListPagination />}
        filters={<StagingOfferFilter />}
        perPage={15}
        empty={<StagingOfferEmptyList />}
        sort={false}
        {...props}
    >
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="establishment.name" />
            <TextField source="title" />
            <ChipField source="status" />
            <DateField source="submissionDate" />
            <TextField source="subject" />
            <TextField source="location.city" />
            <EditButton />
        </Datagrid>
    </List >
);