import { Admin, Resource } from 'react-admin';
import { Amplify } from "@aws-amplify/core";
import { buildAuthProvider } from './lib/auth'
import awsExports from './aws-exports';
import restDataProvider from './lib/rest/data-provider';
import { StagingOfferList } from './components/staging-offer/StagingOfferList';
import { StagingOfferEdit } from './components/staging-offer/StagingOfferEdit';
import { StagingOfferShow } from './components/staging-offer/StagingOfferShow';
import { OfferList } from './components/offer/OfferList';
import { OfferShow } from './components/offer/OfferShow';
import { ExpiredOfferList } from './components/expired-offer/ExpiredOfferList';
import { ExpiredOfferShow } from './components/expired-offer/ExpiredOfferShow';
import HistoryIcon from '@material-ui/icons/History'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/CheckOutlined'
import Bookmarks from '@material-ui/icons/Bookmarks'
import { ApplicationList } from './components/application/ApplicationList';
import { ApplicationShow } from './components/application/ApplicationShow';

Amplify.configure(awsExports);

const dataProvider = restDataProvider('AdminApi');

const App = () => (
  <Admin
    authProvider={buildAuthProvider({ authGroups: ["admin"] })}
    dataProvider={dataProvider}
  >
    <Resource name="staging-offers" icon={EditIcon} list={StagingOfferList} edit={StagingOfferEdit} show={StagingOfferShow} options={{ label: 'Staging offers' }} />
    <Resource name="offers" icon={CheckIcon} list={OfferList} show={OfferShow} options={{ label: 'Live offers' }} />
    <Resource name="expired-offers" icon={HistoryIcon} list={ExpiredOfferList} show={ExpiredOfferShow} options={{ label: 'Expired offers' }} />
    <Resource name="applications" icon={Bookmarks} list={ApplicationList} show={ApplicationShow} options={{ label: 'Applications' }}/>
  </Admin>
);

export default App;