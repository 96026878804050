import {
    Show,
} from 'react-admin';
import { OfferShowItemFields } from '../item-fields/OfferShowItemFields'
import { OfferTitle } from './OfferTitle';

export const OfferShow = (props: any) => (
    <Show
        {...props}
        title={<OfferTitle />}
    >
        <OfferShowItemFields {...props} />
    </Show>
);