import {
    SimpleShowLayout,
    TextField,
    DateField,
    BooleanField,
    EmailField,
    Show,
    ReferenceField,
    FileField,
} from 'react-admin';
import { ApplicationTitle } from './ApplicationTitle';


export const ApplicationShow = (props: any) => {
    return (
        <Show {...props} title={<ApplicationTitle />} >
            <SimpleShowLayout>
                <TextField source="id" />
                <ReferenceField source="offerId" reference="offers" link="show" >
                    <TextField source="title" />
                </ReferenceField>
                <TextField source="firstName" />
                <TextField source="lastName" />
                <EmailField source="email" />
                <TextField source="phone" />
                <TextField style={{ whiteSpace: 'pre-line' }} source="coverLetter" />
                <DateField source="applicationDate" />
                <FileField source="resume.s3Path" label="Resume" />
                <TextField source="prefferedLocale" />
                <BooleanField source="licenceAgreement" />
                <BooleanField source="marketingAgreement" />
            </SimpleShowLayout>
        </Show>
    )
};