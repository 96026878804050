import { Link } from '@material-ui/core';
import { StagingStatusOffer } from 'model';
import { useMemo } from 'react';
import config from '../../config';

const { webappUrl } = config;

export const OfferUrlField = (props: any) => {
    const { slug, status } = props.record;

    const prefix = useMemo(() => (status === StagingStatusOffer.PENDING ? 'pending' : 'offer'), [status])

    const value = `${webappUrl}/job-offer/${prefix}/${slug}`

    return (
        <Link
            target="_blank"
            rel="noopener noreferrer nofollow ugc"
            href={value}        >
            {value}
        </Link>
    );
}


OfferUrlField.defaultProps = {
    addLabel: true,
};